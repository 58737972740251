var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"softwareComponentFullList"},[(_vm.loading)?_c('LoadingPlaceholder'):[_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.softwareComponentFullList),"columns":_vm.kgm_responsiveColumns(),"filterable":true,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.softwareComponentFullList),"sortable":{
        allowUnsort: _vm.kgm_allowUnsort,
        mode: _vm.kgm_sortMode
      },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"typeLink",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('router-link',{staticClass:"alt-primary-color",attrs:{"to":("/installation/" + (props.dataItem.installationId) + "/software-components/" + (props.dataItem.id)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem))+" ")])],1)]}},{key:"installationLink",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('router-link',{staticClass:"alt-primary-color",attrs:{"to":("/installation/" + (props.dataItem.installationId)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem))+" ")])],1)]}},{key:"installationFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"installationFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Installation"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('installationFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"configurationTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('div',{staticStyle:{"white-space":"nowrap","color":"#177ba8 !important"},on:{"click":function($event){return _vm.openModal(props.dataItem.manualConfigurationJson)}}},[_vm._v(" "+_vm._s(props.dataItem.manualConfigurationJson)+" ")])])]}},{key:"autoConfigurationTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('div',{staticStyle:{"white-space":"nowrap","color":"#177ba8 !important"},on:{"click":function($event){return _vm.openModal(props.dataItem.automaticConfigurationJson)}}},[_vm._v(" "+_vm._s(props.dataItem.automaticConfigurationJson)+" ")])])]}},{key:"configurationFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"configurationFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Configuration"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('configurationFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"autoConfigurationFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"autoConfigurationFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Configuration"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('autoConfigurationFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"gtmFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"gtmFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Tag"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('gtmFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"mapsFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"mapsFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Key"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('mapsFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"firebaseFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"firebaseFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Key"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('firebaseFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"cnameTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('span',{style:(("color: " + (props.dataItem.cnameActive ? 'green' : 'red') + ";"))},[_vm._v(" "+_vm._s(props.dataItem.cnameActive ? 'Active' : 'Inactive')+" ")])])]}},{key:"cnameFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"cnameFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Key"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('cnameFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"urlTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.url)?_c('a',{staticClass:"alt-primary-color",attrs:{"href":props.dataItem.url,"target":"_blank"}},[_vm._v(" "+_vm._s(props.dataItem.url)+" ")]):_vm._e()])]}},{key:"urlFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"urlFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a URL"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('urlFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"deploymentImageFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"deploymentImageFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Image"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('deploymentImageFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"deploymentVersionFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"deploymentVersionFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Version"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('deploymentVersionFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"cpuLimitFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"cpuLimitFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Limit"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('cpuLimitFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"cpuRequestFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"cpuRequestFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Request"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('cpuRequestFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"memoryLimitFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"memoryLimitFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Limit"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('memoryLimitFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"memoryRequestFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"memoryRequestFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Request"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('memoryRequestFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"typeFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('select',{key:("typeSelect-" + (props.value)),ref:"typeSelect",staticClass:"form-control mr-2 rounded-right alt-pointer",domProps:{"value":props.value},on:{"change":function (event) {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('pleaseSelectOne'))+" ")]),_vm._l((_vm.componentTypes),function(type,key){return _c('option',{key:("typeSelectOption-" + key),domProps:{"value":type,"selected":_vm.kgm_selectDefaultOption(props, type, 'typeSelect')}},[_vm._v(" "+_vm._s(type)+" ")])})],2),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetSelect('typeSelect', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"deploymentNameFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('select',{key:("typeSelect-" + (props.value)),ref:"deploymentNameSelect",staticClass:"form-control mr-2 rounded-right alt-pointer",domProps:{"value":props.value},on:{"change":function (event) {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('pleaseSelectOne'))+" ")]),_vm._l((_vm.deploymentNames),function(name,key){return _c('option',{key:("typeSelectOption-" + key),domProps:{"value":name,"selected":_vm.kgm_selectDefaultOption(props, name, 'deploymentNameSelect')}},[_vm._v(" "+_vm._s(name)+" ")])})],2),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetSelect('deploymentNameSelect', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}}])})],_c('SweetModal',{ref:"configuration",staticClass:"overflowHidden",attrs:{"title":_vm.$t('configuration'),"blocking":""}},[_c('div',{staticStyle:{"max-height":"550px","overflow":"scroll"}},[_c('pre',[_vm._v(_vm._s(_vm.selectedConfiguration))])]),_c('button',{staticClass:"btn btn-secondary float-right mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.$refs.configuration.close()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"times"}}),_vm._v(_vm._s(_vm.$t('cancel'))+" ")],1),_c('div',{staticClass:"clearfix"})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }