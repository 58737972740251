<template>
  <div class="softwareComponentFullList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(softwareComponentFullList)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(softwareComponentFullList)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="typeLink"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <router-link
              :to="`/installation/${ props.dataItem.installationId }/software-components/${props.dataItem.id}`"
              class="alt-primary-color"
              target="_blank"
            >
              {{ kgm_getNestedValue(props.field, props.dataItem) }}
            </router-link>
          </td>
        </template>
        <template
          slot="installationLink"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <router-link
              :to="`/installation/${ props.dataItem.installationId }`"
              class="alt-primary-color"
              target="_blank"
            >
              {{ kgm_getNestedValue(props.field, props.dataItem) }}
            </router-link>
          </td>
        </template>
        <div
          slot="installationFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="installationFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Installation"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('installationFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="configurationTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div
              style="white-space: nowrap; color: #177ba8 !important;"
              @click="openModal(props.dataItem.manualConfigurationJson)"
            >
              {{ props.dataItem.manualConfigurationJson }}
            </div>
          </td>
        </template>
        <template
          slot="autoConfigurationTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div
              style="white-space: nowrap; color: #177ba8 !important;"
              @click="openModal(props.dataItem.automaticConfigurationJson)"
            >
              {{ props.dataItem.automaticConfigurationJson }}
            </div>
          </td>
        </template>
        <div
          slot="configurationFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="configurationFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Configuration"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('configurationFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="autoConfigurationFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="autoConfigurationFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Configuration"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('autoConfigurationFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="gtmFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="gtmFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Tag"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('gtmFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="mapsFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="mapsFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Key"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('mapsFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="firebaseFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="firebaseFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Key"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('firebaseFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="cnameTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span :style="`color: ${props.dataItem.cnameActive ? 'green' : 'red'};`">
              {{ props.dataItem.cnameActive ? 'Active' : 'Inactive' }}
            </span>
          </td>
        </template>
        <div
          slot="cnameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="cnameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Key"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('cnameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="urlTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <a 
              v-if="props.dataItem.url"
              :href="props.dataItem.url" 
              target="_blank"
              class="alt-primary-color"
            >
              {{ props.dataItem.url }}
            </a>
          </td>
        </template>
        <div
          slot="urlFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="urlFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a URL"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('urlFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="deploymentImageFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="deploymentImageFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Image"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('deploymentImageFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="deploymentVersionFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="deploymentVersionFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Version"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('deploymentVersionFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="cpuLimitFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="cpuLimitFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Limit"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('cpuLimitFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="cpuRequestFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="cpuRequestFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Request"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('cpuRequestFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="memoryLimitFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="memoryLimitFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Limit"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('memoryLimitFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="memoryRequestFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="memoryRequestFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Request"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('memoryRequestFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="typeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="typeSelect"
            :key="`typeSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(type, key) in componentTypes"
              :key="`typeSelectOption-${key}`"
              :value="type"
              :selected="kgm_selectDefaultOption(props, type, 'typeSelect')"
            >
              {{ type }}
            </option>
          </select>
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('typeSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="deploymentNameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="deploymentNameSelect"
            :key="`typeSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(name, key) in deploymentNames"
              :key="`typeSelectOption-${key}`"
              :value="name"
              :selected="kgm_selectDefaultOption(props, name, 'deploymentNameSelect')"
            >
              {{ name }}
            </option>
          </select>
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('deploymentNameSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
      </Grid>
    </template>
    <SweetModal
      ref="configuration"
      :title="$t('configuration')"
      blocking
      class="overflowHidden"
    >
      <div style="max-height: 550px; overflow: scroll;">
        <pre>{{ selectedConfiguration }}</pre>
      </div>
      <button
        slot="button"
        class="btn btn-secondary float-right mb-3"
        @click="$refs.configuration.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>

import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: "SoftwareComponentFullList",
  components: {
    SweetModal
  },
  mixins: [
    kendoGridMixin
  ],
  data () {
    return {
      loading: true,
      softwareComponentFullList: null,
      selectedConfiguration: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: 'key',
            operator: 'contains',
            value: ''
          }
        ]
      },
      kgm_sort: [
        {
          "field": "key",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'installation',
          filterable: true,
          filter: 'text',
          title: 'Installation',
          filterCell: 'installationFilter',
          cell: 'installationLink'
        },
        {
          field: 'type',
          filterable: true,
          filter: 'text',
          title: 'Type',
          filterCell: 'typeFilter',
          cell: 'typeLink'
        },
        {
          field: 'deploymentName',
          filterable: true,
          filter: 'text',
          title: 'Deployment Name',
          filterCell: 'deploymentNameFilter'
        },
        {
          field: 'deploymentImage',
          filterable: true,
          filter: 'text',
          title: 'Deployment Image',
          filterCell: 'deploymentImageFilter',
          hideOn: ['mdDown']
        },
        {
          field: 'deploymentVersion',
          filterable: true,
          filter: 'text',
          title: 'Deployment Version',
          filterCell: 'deploymentVersionFilter',
        },
        {
          field: 'cpuLimit',
          filterable: true,
          filter: 'text',
          title: 'CPU Limit',
          filterCell: 'cpuLimitFilter',
          hideOn: ['mdDown']
        },
        {
          field: 'cpuRequest',
          filterable: true,
          filter: 'text',
          title: 'CPU Request',
          filterCell: 'cpuLimitFilter',
          hideOn: ['mdDown']
        },
        {
          field: 'memoryLimit',
          filterable: true,
          filter: 'text',
          title: 'Memory Limit',
          filterCell: 'memoryLimitFilter',
          hideOn: ['mdDown']
        },
        {
          field: 'memoryRequest',
          filterable: true,
          filter: 'text',
          title: 'Memory Request',
          filterCell: 'memoryRequestFilter',
          hideOn: ['mdDown']
        },
        {
          field: 'url',
          filterable: true,
          filter: 'text',
          title: 'URL',
          filterCell: 'urlFilter',
          cell: 'urlTemplate'
        },
        {
          field: 'automaticConfigurationJson',
          filterable: true,
          filter: 'text',
          title: 'Auto Configuration',
          filterCell: 'autoConfigurationFilter',
          cell: 'autoConfigurationTemplate'
        },
        {
          field: 'manualConfigurationJson',
          filterable: true,
          filter: 'text',
          title: 'Manual Configuration',
          filterCell: 'configurationFilter',
          cell: 'configurationTemplate',
          hideOn: ['mdDown']
        },
        {
          field: 'cname',
          filterable: true,
          filter: 'text',
          title: 'CNAME',
          filterCell: 'cnameFilter',
          cell: 'cnameTemplate',
          hideOn: ['mdDown']
        }
      ]
    }
  },
  computed: {
    componentTypes () {
      if (!this.softwareComponentFullList) {
        return [ "" ];
      }
      let componentTypesL = Array.from(new Set(this.softwareComponentFullList.map(o=>o.type)));
      //Remove null values
      componentTypesL = componentTypesL.filter(function (e) {return e});
      return componentTypesL.sort();
    },
    deploymentNames () {
      if (!this.softwareComponentFullList) {
        return [ "" ];
      }
      let deploymentNamesL = Array.from(new Set(this.softwareComponentFullList.map(o=>o.deploymentName)));
      //Remove null values
      deploymentNamesL = deploymentNamesL.filter(function (e) {return e});
      return deploymentNamesL.sort();
    },
  },
  metaInfo () {
    return {
      title: this.$t('softwareComponent.softwareComponent')
    }
  },
  created () {
    if(this.$route.params.url) {
      this.kgm_filter.filters.push({
            field: 'url',
            operator: 'contains',
            value: this.$route.params.url
          });
    }
    this.getSoftwareComponentFullList();
  },
  methods: {
    openModal (val) {
      if(val) {
       try {
          var parseJSON = JSON.parse(val);
          this.selectedConfiguration = JSON.stringify(parseJSON, undefined, 4);
       } catch  {
         this.selectedConfiguration = val;
       }
      }
      else {
        this.selectedConfiguration = null;
      }
      this.$refs.configuration.open();
    },
    //api
    getSoftwareComponentFullList () {
      this.loading = true;
      this.axios.get('/SoftwareComponent/GetGroupedSoftwareComponentsAsync')
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.softwareComponentFullList = response.data;
        if(this.$route.params.name) {
          this.softwareComponentFullList = this.softwareComponentFullList.filter(x => x.installationId == this.$route.params.name);
        }
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
<style>
.softwareComponentFullList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>